import Plotly from 'plotly.js';

import React from 'react';

const Graph = ({ id, data, layout = {} }) => {    
    const generateGraph = React.useCallback(() => {
        Plotly.newPlot(id, data, layout, { displayModeBar: false });
    }, [id, data, layout]);

    React.useLayoutEffect(() => {
        generateGraph();
    }, [data, layout, id]);

    React.useEffect(() => {
        const evt = window.addEventListener('resize', event => {
            generateGraph();
        });

        return () => window.removeEventListener('resize', evt);
    }, [generateGraph]);

    return (
        <div id={id} className="no-break-print"></div>
    );
};

export default Graph;