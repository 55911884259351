import * as actionTypes from './actionTypes';

export const submitPage = (page, personIdx = 0, formValues = {}) => ({
    type: actionTypes.SUBMIT_PAGE,
    payload: {
        page,
        personIdx,
        formValues
    }
});

export const goBack = (page) => ({
    type: actionTypes.GO_BACK,
    payload: {
        page
    }
});

export const showFaq = () => ({
    type: actionTypes.SHOW_FAQ
});

export const hideFaq = () => ({
    type: actionTypes.HIDE_FAQ
});
