const toSortedTuples = (obj, keyName, valName) => Object.entries(obj).sort(([a], [b]) => a - b).map(([key, val]) => ({ [keyName]: key, [valName]: val }));

export const toAgeSurvivalGraphData = (name, type, data, otherData = {}) => {
    const tuples = toSortedTuples(data, 'key', 'val');

    return {
        name,
        type,
        x: tuples.map(tuple => tuple.key),
        y: tuples.map(tuple => Number.isNaN(tuple.val) ? 0 : tuple.val),
        text: tuples.map(tuple => Number.isNaN(tuple.val) ? '*' : ''),
        textposition: 'outside',
        ...otherData
    };
};

const screenGraphDataByTheme = {
    'international': {
        person1: { marker: { color: '#b3d954' }, line: { width: 3 } },
        person2: { marker: { color: '#846fe0' }, line: { width: 3 } },
        either: { marker: { color: '#e55570'}, line: { width: 3 } },
        both: { marker: { color: '#5fbfdb'}, line: { width: 3 } }
    },
    'us': {
        person1: { marker: { color: '#67c197' }, line: { width: 3 } },
        person2: { marker: { color: '#dd407c' }, line: { width: 3 } },
        either: { marker: { color: '#7b51cd'}, line: { width: 3 } },
        both: { marker: { color: '#06b4c5'}, line: { width: 3 } }
    }
};

const printGraphData = {
    person1: { marker: { color: 'black', pattern: { shape: '' } }, line: { width: 3 } },
    person2: { marker: { color: 'black', pattern: { shape: 'x' } }, line: { width: 3 } },
    either: { marker: { color: 'black', pattern: { shape: '-' } }, line: { width: 3 } },
    both: { marker: { color: 'black', pattern: { shape: '|' } }, line: { width: 3 } }
};

export const toGraphData = (profiles, graphType, dataObject, type = 'screen', theme = 'international') => {
    const graphData = type === 'screen' ? screenGraphDataByTheme[theme] : printGraphData;

    const result = [
        toAgeSurvivalGraphData(profiles[0].name, graphType, dataObject.person1, graphData.person1)
    ];

    if (profiles[1]) {
        result.push(toAgeSurvivalGraphData(profiles[1].name, graphType, dataObject.person2, graphData.person2));
        result.push(toAgeSurvivalGraphData('Either', graphType, dataObject.either, graphData.either));
        result.push(toAgeSurvivalGraphData('Both', graphType, dataObject.both, graphData.both));
    }

    return result;
}

export const decimalToPercentage = decimal => Number.isNaN(decimal) ? '*' : `${(decimal * 100).toFixed(0)}%`;
