import React from 'react';
import InfoTooltip from './InfoTooltip';
import Graph from '../Graphs/graph';
import SettingsContext from '../../utils/contexts/SettingsContext';
import { toGraphData, decimalToPercentage } from './utils';
import min from 'lodash/min';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import keysIn from 'lodash/keysIn';
import valuesIn from 'lodash/valuesIn';

const specificAgePopulatedText = (text, probSurvivingByAge) => {
    let workingCopy = text;

    const minAge = min(map(uniq(flatMap(valuesIn(probSurvivingByAge), keysIn)), ageStr => +ageStr));
    let ages = [];
    for (let i = minAge; i < minAge + 15; i = Math.round(i + 5)) {
        ages.push(i);
    }
    workingCopy = workingCopy.replace(/\{ages\}/g, ages.join(', '));

    return workingCopy;
}

const ProbLivingToAgeDisplay = ({ probSurvivingByAge, probSurvivingYearsAfterRetirement, profiles, country }) => {
    const { resultsPageCopy: { SpecificAge, SpecificAgeTooltip }, sectionTitles: { ProbabilityOfLivingToACertainAge: headerText }, template } = React.useContext(SettingsContext);

    const desktopAgeSurvivalGraphData = React.useMemo(() => toGraphData(profiles, 'bar', probSurvivingByAge, 'screen', template), [probSurvivingByAge, profiles]);
    const printAgeSurvivalGraphData = React.useMemo(() => toGraphData(profiles, 'bar', probSurvivingByAge, 'print', template), [probSurvivingByAge, profiles]);

    const probSurvivingNumYearsAfterRetirementTuples = React.useMemo(() => Object.entries(probSurvivingYearsAfterRetirement.person1).sort(([a], [b]) => a - b).map(([numYearsAfterRetirement, person1]) => {
        const tuple = { numYearsAfterRetirement, person1: decimalToPercentage(person1) };

        if (probSurvivingYearsAfterRetirement.person2 && probSurvivingYearsAfterRetirement.person2[numYearsAfterRetirement] !== undefined) {
            tuple.person2 = decimalToPercentage(probSurvivingYearsAfterRetirement.person2[numYearsAfterRetirement]);
        }

        return tuple;
    }), [probSurvivingYearsAfterRetirement]);

    return (
        <div className="mb-6">
            <div className="mb-2">
                <h3>{headerText} <InfoTooltip id="prob-age-tooltip" contents={specificAgePopulatedText(SpecificAgeTooltip[country][profiles.length.toString()], probSurvivingByAge)} /></h3>
                <div dangerouslySetInnerHTML={{ __html: specificAgePopulatedText(SpecificAge[country][profiles.length.toString()], probSurvivingByAge)}}></div>
                <div className="print-only" dangerouslySetInnerHTML={{ __html: SpecificAgeTooltip[country][profiles.length.toString()] }}></div>
                <div className="no-print">
                    <Graph 
                        data={desktopAgeSurvivalGraphData}
                        layout={{
                            xaxis: {
                                type: 'category',
                                tickcolor: 'white',
                                showgrid: false,
                                tickfont: {
                                    color: 'white'
                                },
                                title: {
                                    text: 'Age',
                                    font: {
                                        color: 'white'
                                    }
                                }
                            },
                            yaxis: {
                                range: [0, 1],
                                tickformat: ',.0%',
                                tickcolor: 'white',
                                gridcolor: 'white',
                                tickfont: {
                                    color: 'white'
                                },
                                title: {
                                    text: 'Probability',
                                    font: {
                                        color: 'white'
                                    }
                                }
                            },
                            legend: {
                                x: 1,
                                y: 0.5,
                                font: {
                                    color: 'white'
                                }
                            },
                            margin: {
                                t: 10
                            },
                            paper_bgcolor: 'transparent',
                            plot_bgcolor: 'transparent'
                        }}
                        id="age-display"
                    />
                </div>
                <div className="print-only">
                    <Graph 
                        data={printAgeSurvivalGraphData}
                        layout={{
                            xaxis: {
                                type: 'category',
                                tickcolor: 'black',
                                showgrid: false,
                                tickfont: {
                                    color: 'black'
                                },
                                title: {
                                    text: 'Age',
                                    font: {
                                        color: 'black'
                                    }
                                }
                            },
                            yaxis: {
                                range: [0, 1],
                                tickformat: ',.0%',
                                tickcolor: 'black',
                                gridcolor: 'black',
                                tickfont: {
                                    color: 'black'
                                },
                                title: {
                                    text: 'Probability',
                                    font: {
                                        color: 'black'
                                    }
                                }
                            },
                            legend: {
                                x: 1,
                                y: 0.5,
                                font: {
                                    color: 'black'
                                }
                            },
                            margin: {
                                t: 10
                            },
                            paper_bgcolor: 'transparent',
                            plot_bgcolor: 'transparent'
                        }}
                        id="age-display-print"
                    />
                </div>
            </div>
            <div className="results-table-wrap no-print-break">
                <table className="table results-table">
                    <thead>
                        <tr>
                            <th rowSpan="2">Years Since Retirement</th>
                            <th colSpan="2">{profiles[0].name}</th>
                            {profiles[1] ? <th colSpan="2">{profiles[1].name}</th> : null}
                        </tr>
                        <tr>
                            <th>Age</th>
                            <th>Probability</th>
                            {profiles[1] ? <React.Fragment><th>Age</th><th>Probability</th></React.Fragment> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            probSurvivingNumYearsAfterRetirementTuples.map(tuple => (
                                <tr key={tuple.numYearsAfterRetirement}>
                                    <td>{tuple.numYearsAfterRetirement}</td>
                                    <td>{Math.floor(+profiles[0].retirementAge + +tuple.numYearsAfterRetirement)}</td>
                                    <td>{tuple.person1}</td>
                                    {tuple.person2 !== undefined ? <React.Fragment><td>{Math.floor(+profiles[1].retirementAge + +tuple.numYearsAfterRetirement)}</td><td>{tuple.person2}</td></React.Fragment> : null}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProbLivingToAgeDisplay;
