import React from 'react';
import PersonColumn from './PersonColumn';
import { useFormikContext } from 'formik';
import PersonBadge from './PersonBadge';

const PeopleEditor = ({ fieldsToShow, resultsPage = false }) => {
    const {
        values,
        setFieldValue
    } = useFormikContext();

    const indices = [];
    for (let i = 0; i < values.numberOfPeople; i++) {
        indices.push(i);
    }

    const onClickRemove = React.useCallback(idx => event => {
        event.preventDefault();

        setFieldValue('people', [...values.people.slice(0, idx), ...values.people.slice(idx + 1)]);
        setFieldValue('numberOfPeople', +values.numberOfPeople - 1);
    }, [setFieldValue, values.people, values.numberOfPeople]);

    const onClickAdd = React.useCallback(event => {
        event.preventDefault();

        setFieldValue('numberOfPeople', +values.numberOfPeople + 1);
    }, [setFieldValue, values.numberOfPeople]);

    return (
        <div className="row mb-2">
            {
                indices.map(
                    index => (
                        <div key={index} className={values.numberOfPeople == 1 ? 'col-12' : 'col-6'}>
                            {
                                values.numberOfPeople > 1 ? (
                                    <div className="d-flex no-print align-items-center">
                                        <PersonBadge personIdx={index} />
                                        <div className="flex-spacer"></div>
                                        <button className="remove-person-button" onClick={onClickRemove(index)} type="button">
                                            Remove
                                        </button>
                                    </div>
                                ) : (
                                    <div>
                                        <PersonBadge personIdx={index} />
                                    </div>
                                )
                            }
                            
                            
                            <PersonColumn key={index} idx={index} fieldsToShow={fieldsToShow} resultsPage={resultsPage} />

                            {
                                values.numberOfPeople == 1 ? (
                                    <div className="d-flex justify-content-center no-print">
                                        <button className="add-person-button" onClick={onClickAdd} type="button">
                                            <i className="fa fa-plus"></i> Add second person
                                        </button>
                                    </div>
                                ) : null
                            }
                        </div>
                    )
                )
            }
        </div>
    );
};

export default PeopleEditor;