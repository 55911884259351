import { shouldShowFieldForCountry } from '../../../business';

const validatorForFields = (countries, fieldsToShow) => (values) => {
    const errors = {};

    for (const personIdx in values.people) { // for each person, validate that all fields shown on that page are present
        const person = values.people[personIdx];

        for (const fieldKey in person) {
            if (fieldsToShow.includes(fieldKey) && shouldShowFieldForCountry(countries, person.country, fieldKey) && person[fieldKey] === '') {
                if (!errors.people) {
                    errors.people = [];
                }

                while (!errors.people[personIdx]) {
                    errors.people.push({});
                }

                errors.people[personIdx][fieldKey] = 'This field is required';
            } 
        }

        if (fieldsToShow.includes('age') && personIdx == 0 && fieldsToShow.includes('retirementAge') && person.retirementAge < person.age) { // for the first person, validate that retirement age is >= current age (ignore for others since retirement age is automatically calculated)
            if (!errors.people) {
                errors.people = [];
            }

            while (!errors.people[personIdx]) {
                errors.people.push({});
            }

            errors.people[personIdx].retirementAge = 'Retirement age needs to be greater than or equal to current age';
        }
    }

    return errors;
};

export default validatorForFields;
