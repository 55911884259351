import React from 'react';
import { Formik } from 'formik';
import FAQs from '../FAQs';
import PersonBadge from '../FormPages/PeopleEditor/PersonBadge';
import { reportAnalyticsEvent } from '../../utils/ga';

const FormLayout = ({ initialValues, onSubmit, onGoBack, validator, title, children, progress = 0, faqs = null, personIdx = null }) => {
    React.useEffect(() => {
        document.querySelector('.main').classList.add('with-form-continue');
        window.scrollTo(0, 0);

        return () => {
            document.querySelector('.main').classList.remove('with-form-continue');
        }
    }, []);

    const onClickGoBack = React.useCallback(event => {
        event.preventDefault();
        onGoBack();
        reportAnalyticsEvent('form_back_button', {
            page: title
        });
    }, [onGoBack]);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validator} validateOnChange={false} validateOnBlur={false}>
            {
                ({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 profile-form-header text-center p-3">
                                    {personIdx != null ? (
                                        <PersonBadge personIdx={personIdx} />
                                    ) : null}
                                    <h2>{title}</h2>
                                </div>
                            </div>
                            <div className="profile-form">
                                {children}
                            </div>
                            {
                                faqs ? <FAQs faqs={faqs} country={initialValues.country} /> : null
                            }
                        </div>
                        <div className="form-continue-footer">
                            <div className="form-continue-footer__buttons">
                                {
                                    onGoBack ? (
                                        <button className="go-back-button" onClick={onClickGoBack}><img src="/Static/images/icons/arrow-l.svg" alt="go back" /></button>
                                    ) : null
                                }
                                <button type="submit" className="btn btn-primary">Continue</button>
                                
                            </div>
                            
                            <div className="form-continue-footer__progress-bar">
                                <div className="completed-part" style={{ width: `${progress * 100}%`}}></div>
                                <div className="incomplete-part"></div>
                            </div>
                        </div>
                    </form>
                )
            }
        </Formik>
    );
}

export default FormLayout;
