import React from 'react';
import { Formik } from 'formik';
import validatorForFields from '../PeopleEditor/validator';
import PersonColumn from '../PeopleEditor/PersonColumn';
import FormLayout from '../../FormElements/FormLayout';
import SettingsContext from '../../../utils/contexts/SettingsContext';
import { reportAnalyticsEvent } from '../../../utils/ga';

const shownFields = ['name', 'age', 'retirementAge', 'sex'];

const PersonDemographicsPage = ({ initialValues, onSubmit, onGoBack, personIdx, numberOfPeople }) => {
    const { countries, faqs: { DemographicsFaq }, sectionTitles: { BasicInformation: title } } = React.useContext(SettingsContext);

    React.useEffect(() => {
        reportAnalyticsEvent('view_basic_info', {
            personNumber: +personIdx + 1,
            numberOfPeople
        });
    }, []);

    return (
        <FormLayout 
            initialValues={initialValues}
            onSubmit={onSubmit}
            onGoBack={onGoBack}
            validator={validatorForFields(countries, shownFields)}
            title={title}
            personIdx={personIdx}
            faqs={DemographicsFaq}
            progress={numberOfPeople == 1 ? 0.33 : (personIdx == 0 ? 0.2 : 0.6)}>
            <PersonColumn idx={personIdx} fieldsToShow={shownFields} />
        </FormLayout>
    );
};

export default PersonDemographicsPage;
