import React from 'react';
import { Formik } from 'formik';
import SettingsContext from '../../utils/contexts/SettingsContext';
import validatorForFields from '../FormPages/PeopleEditor/validator';
import PeopleEditor from '../FormPages/PeopleEditor';
import { reportAnalyticsEvent } from '../../utils/ga';
import MobileResultsPageForm from './MobileResultsPageForm';

const shownFields = ['name', 'country', 'age', 'sex', 'retirementAge', 'smoker', 'healthStatus'];

const ResultsPageForm = ({ values, onUpdateValues }) => {
    const { countries } = React.useContext(SettingsContext);
    const floatingResultsFormRef = React.useRef();
    const floatingResultsWrapperRef = React.useRef();
    const [numRecalculations, setNumRecalculations] = React.useState(0);

    const fieldsToShow = countries.length > 1 ? shownFields : shownFields.filter(field => !['country'].includes(field));

    // make the sidebar floating
    React.useLayoutEffect(() => {
        // adjust the width to match its parent
        const adjustWidth = () => floatingResultsFormRef.current.style.width = `${floatingResultsWrapperRef.current.getBoundingClientRect().width}px`;
        window.addEventListener('resize', adjustWidth);
        adjustWidth();

        // when scrolling, go below the navbar wrap, or if the navbar wrap is outside of the viewport, 25px from the top of the screen
        const adjustVerticalPosition = () => floatingResultsFormRef.current.style.top = `${Math.max(25, document.querySelector('.navbar-wrap').getBoundingClientRect().height - window.scrollY)}px`;
        window.addEventListener('scroll', adjustVerticalPosition);
        adjustVerticalPosition();

        return () => {
            window.removeEventListener('resize', adjustWidth);
            window.removeEventListener('scroll', adjustVerticalPosition);
        }
    }, [floatingResultsFormRef, floatingResultsWrapperRef]);

    const onSubmit = React.useCallback((formValues) => {
        onUpdateValues(formValues);
        window.scrollTo(0, 0);
        reportAnalyticsEvent('recalculate_results', {
            numRecalculations,
            numberOfPeople: formValues.numberOfPeople
        });
        setNumRecalculations(numRecalculations + 1);
    }, [onUpdateValues, numRecalculations, setNumRecalculations]);

    return (
        <React.Fragment>
            <div className="results-page-form-wrap d-none d-lg-block" ref={floatingResultsWrapperRef}>
                <Formik initialValues={values} onSubmit={onSubmit} validate={validatorForFields(countries, fieldsToShow)} validateOnBlur={false} validateOnChange={false}>
                    {
                        ({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} className="results-page-form" ref={floatingResultsFormRef}>
                                <h2 className="print-only">Information</h2>
                                <PeopleEditor fieldsToShow={fieldsToShow} resultsPage={true} />
                                <div className="results-page-form__footer-wrap no-print">
                                    <div className="results-page-form__footer">
                                        <button type="submit" className="btn btn-primary btn__update-results">Update Results</button>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </Formik>
            </div>
            <MobileResultsPageForm onSubmit={onSubmit} initialValues={values} />
        </React.Fragment>
    );
};

export default ResultsPageForm;
