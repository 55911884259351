import { reportAnalyticsEvent } from "../utils/ga";
import initFaq from './faq';
import React from 'react';
import { render } from 'react-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import reducer from '../store';
import ProbabilityOfSurvivalApp from '../containers/ProbabilityOfSurvivalApp';

const openMobileNavbar = () => {
    document.querySelector('.mobile-navbar').classList.remove('d-none');

    // also toggle the open/close icon
    document.querySelector('.js-expand-mobile-nav-icon').classList.add('d-none');
    document.querySelector('.js-collapse-mobile-nav-icon').classList.remove('d-none');
    
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
}

const closeMobileNavbar = () => {
    document.querySelector('.mobile-navbar').classList.add('d-none');
    document.querySelector('.js-expand-mobile-nav-icon').classList.remove('d-none');
    document.querySelector('.js-collapse-mobile-nav-icon').classList.add('d-none');
    document.body.style.overflow = 'auto';
}

const toggleMobileNavbar = () => {
    if (document.querySelector('.mobile-navbar').classList.contains('d-none')) {
        openMobileNavbar();
    } else {
        closeMobileNavbar();
    }
}

const openTerms = (allowContinuingToForm = false) => {
    closeMobileNavbar();
    document.querySelector('.terms-modal').classList.remove('d-none');

    if (allowContinuingToForm) {
        document.querySelector('.js-agree').classList.remove('d-none');
        document.querySelector('.js-agree-essential').classList.remove('d-none');
        document.querySelector('.js-terms-ok').classList.add('d-none');
    } else {
        document.querySelector('.js-agree').classList.add('d-none');
        document.querySelector('.js-agree-essential').classList.add('d-none');
        document.querySelector('.js-terms-ok').classList.remove('d-none');
    }
}

const closeTerms = () => {
    document.querySelector('.terms-modal').classList.add('d-none');
}

const openApp = () => {
    closeTerms();
    closeMobileNavbar();
    document.querySelector('.intro').classList.add('d-none');
    document.querySelector('#probability-of-survival-tool').classList.remove('d-none');
    document.querySelector('.navbar-wrap').classList.remove('hero-gradient-bg');
    reportAnalyticsEvent('click_accept_terms');

    const domRoot = document.getElementById('probability-of-survival-tool');
    const store = configureStore({
        reducer
    });

    // get props from the dataset
    const dataset = Object.assign({}, domRoot.dataset);
    const props = Object.fromEntries(Object.entries(dataset).map(([key, value]) => [key, JSON.parse(value)]));

    render(<Provider store={store}><ProbabilityOfSurvivalApp {...props} /></Provider>, domRoot);
}

const initDom = () => {
    for (const startButton of document.getElementsByClassName('js-start')) {
        startButton.addEventListener('click', event => {
            event.preventDefault();

            if (document.querySelector('#probability-of-survival-tool').classList.contains('d-none')) {
                openTerms(true);
            }
        });
    }

    for (const agreeButton of document.getElementsByClassName('js-agree')) {
        agreeButton.addEventListener('click', event => {
            event.preventDefault();

            window.acceptGaTracking = true;
            openApp();
        });
    }

    for (const agreeEssentialButton of document.getElementsByClassName('js-agree-essential')) {
        agreeEssentialButton.addEventListener('click', event => {
            event.preventDefault();

            window.acceptGaTracking = false;
            openApp();
        });
    }

    for (const closeTermsButton of document.getElementsByClassName('js-terms-modal-close')) {
        closeTermsButton.addEventListener('click', event => {
            event.preventDefault();

            document.querySelector('.terms-modal').classList.add('d-none');
        });
    }

    for (const mobileNavButton of document.getElementsByClassName('js-expand-mobile-nav')) {
        mobileNavButton.addEventListener('click', event => {
            event.preventDefault();

            toggleMobileNavbar();
        });
    }

    for (const closeMobileNavButton of document.getElementsByClassName('js-close-mobile-nav')) {
        closeMobileNavButton.addEventListener('click', event => {
            event.preventDefault();

            closeMobileNavbar();
        });
    }

    for (const openTermsButton of document.getElementsByClassName('js-terms')) {
        openTermsButton.addEventListener('click', event => {
            event.preventDefault();

            openTerms(false);
        });
    }

    // automatically start the app (open the terms modal) if ?start=true is in the URL
    if (window.location.search.includes('start=true')) {
        openTerms(true);

        const url = new URL(window.location);
        url.searchParams.delete('start');
        window.history.pushState({}, '', url);
    }

    initFaq();
};

export default initDom;