import * as actionTypes from './actionTypes';

export const submitFormValues = (values) => ({
    type: actionTypes.SUBMIT_FORM_PAGE,
    payload: values
});

export const setCountries = (countries) => ({
    type: actionTypes.SET_COUNTRIES,
    payload: countries
});
