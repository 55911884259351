import { Formik } from 'formik';
import React from 'react';
import SettingsContext from '../../../utils/contexts/SettingsContext';
import FormLayout from '../../FormElements/FormLayout';
import CommonValuesFragment from './CommonValuesFragment';

const validator = (values) => {
    const errors = {};

    if (!['1', '2'].includes(values.numberOfPeople)) {
        errors.numberOfPeople = 'This value is required';
    }

    if (!values.country.trim()) {
        errors.country = 'This value is required';
    }

    return errors;
}

const CommonValuesPage = ({ initialValues, onSubmit }) => {
    const { faqs: { GeneralInfoFaq }, sectionTitles: { BasicInformation: heading } } = React.useContext(SettingsContext);

    return (
        <FormLayout initialValues={initialValues} onSubmit={onSubmit} validator={validator} title={heading} faqs={GeneralInfoFaq} progress={0}>
            <CommonValuesFragment />
        </FormLayout>
    );
};

export default CommonValuesPage;