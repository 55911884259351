import React from 'react';
import clsx from 'clsx';

const PersonBadge = ({ personIdx }) => {
    return (
        <div className="d-flex justify-content-center">
            <div className={clsx('person-number-badge', personIdx === 0 ? 'person-number-badge__one' : 'person-number-badge__two')}>
                Person {personIdx === 0 ? 'One' : 'Two'}
            </div>
        </div>
    );
}

export default PersonBadge;