import React from 'react';
import minBy from 'lodash/minBy';
import { ProbabilityOfSurvivalCalculator } from '../../business';
import SettingsContext from '../../utils/contexts/SettingsContext';
import ResultsPageForm from './ResultsPageForm';
import ProbLivingToAgeDisplay from './ProbLivingToAgeDisplay';
import PlanningHorizonDisplay from './PlanningHorizonDisplay';
import ProbabilityOfLivingASpecifiedNumberOfYearsDisplay from './ProbabilityOfLivingASpecifiedNumberOfYearsDisplay';
import { reportAnalyticsEvent } from '../../utils/ga';
import FAQs from '../FAQs';

const isNoDataAvailable = num => Number.isNaN(num) || num < 0.01;

const ResultsPage = ({ values, onUpdateValues }) => {
    const { statisticalData, bottomText, sectionTitles: { ResultsPage: headerText }, faqs: { ResultsPageIntro: faqs }, resultsPageCopy: { ResultsPageIntro } } = React.useContext(SettingsContext);

    const calculator = React.useMemo(() => new ProbabilityOfSurvivalCalculator(statisticalData), [statisticalData])

    const profiles = React.useMemo(() => {
        const firstPerson = values.people[0];

        return values.people.map(person => ({ 
            ...person, 
            birthYear: new Date().getFullYear() - person.age,
            country: values.country,
            retirementAge: +person.age + (+firstPerson.retirementAge - +firstPerson.age) // always calculate retirement age based on the first person
        }));
    }, [values]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        reportAnalyticsEvent('view_results_page', {
            numberOfPeople: profiles.length
        });
        document.querySelector('.main').classList.add('no-watermark');

        return () => {
            document.querySelector('.main').classList.remove('no-watermark');
        }
    }, []);

    const probSurvivingYearsAfterRetirement = React.useMemo(() => {
        const survivalProbByYearsAfterRetirement = {
            person1: {}
        };

        if (profiles[1]) {
            survivalProbByYearsAfterRetirement.person2 = {};
            survivalProbByYearsAfterRetirement.either = {}
            survivalProbByYearsAfterRetirement.both = {};
        }

        let yearsAfterRetirement = 0;
        do {
            const person1SurvivalProb = calculator.totalProbabilityOfSurvival(profiles[0], profiles[0].retirementAge, profiles[0].retirementAge + yearsAfterRetirement);
                        
            if (!profiles[1] && Number.isNaN(person1SurvivalProb) || person1SurvivalProb < 0.01) {
                break;
            }
            
            if (profiles[1]) {
                const person2SurvivalProb = calculator.totalProbabilityOfSurvival(profiles[1], profiles[1].retirementAge, profiles[1].retirementAge + yearsAfterRetirement);
                const eitherSurvivalProb = calculator.probabilityOfOneSurvivingToAge(profiles[0], profiles[0].retirementAge, profiles[0].retirementAge + yearsAfterRetirement, profiles[1], profiles[1].retirementAge, profiles[1].retirementAge + yearsAfterRetirement);
                const bothSurvivalProb = calculator.probabilityOfBothSurvivingToAge(profiles[0], profiles[0].retirementAge, profiles[0].retirementAge + yearsAfterRetirement, profiles[1], profiles[1].retirementAge, profiles[1].retirementAge + yearsAfterRetirement);
            
                if ([person1SurvivalProb, person2SurvivalProb].every(isNoDataAvailable)) {
                    break;
                }

                survivalProbByYearsAfterRetirement.person2[yearsAfterRetirement] = person2SurvivalProb;
                survivalProbByYearsAfterRetirement.either[yearsAfterRetirement] = eitherSurvivalProb;
                survivalProbByYearsAfterRetirement.both[yearsAfterRetirement] = bothSurvivalProb;
            }

            survivalProbByYearsAfterRetirement.person1[yearsAfterRetirement] = person1SurvivalProb;

            yearsAfterRetirement += 5;
        } while (true);

        return survivalProbByYearsAfterRetirement;
    }, [profiles, calculator]);

    const probSurvivingByAge = React.useMemo(() => {
        const survivalProbByAge = {
            person1: {}
        };

        if (profiles[1]) {
            survivalProbByAge.person2 = {};
            survivalProbByAge.either = {}
            survivalProbByAge.both = {};
        }

        const minAgeToShow = Math.ceil(minBy(profiles, 'retirementAge').retirementAge / 5) * 5;

        let age = minAgeToShow;
        do {
            const person1SurvivalProb = calculator.totalProbabilityOfSurvival(profiles[0], profiles[0].retirementAge, age);
            if (!profiles[1] && isNoDataAvailable(person1SurvivalProb)) {
                break;
            }
                
            if (profiles[1]) {
                const person2SurvivalProb = calculator.totalProbabilityOfSurvival(profiles[1], profiles[1].retirementAge, age);;

                if ([person1SurvivalProb, person2SurvivalProb].every(isNoDataAvailable)) {
                    break;
                }

                survivalProbByAge.person2[age] = person2SurvivalProb;
            }

            survivalProbByAge.person1[age] = person1SurvivalProb;

            age += 5;
        } while (true);

        return survivalProbByAge;
    }, [profiles, calculator]);

    const numYearsByProbability = React.useMemo(() => {
        const numYearsByProb = {
            person1: {}
        };

        if (profiles[1]) {
            numYearsByProb.person2 = {};
            numYearsByProb.either = {};
            numYearsByProb.both = {};
        }

        const relevantPercentages = [90, 75, 50, 25, 10];

        for (const percentage of relevantPercentages) {
            const percentageStr = `${percentage}%`;

            const person1NumYears = calculator.numYearsFromProbability(profiles[0], profiles[0].retirementAge, percentage / 100);;
            if (!Number.isNaN(person1NumYears)) {
                numYearsByProb.person1[percentageStr] = person1NumYears;

                if (profiles[1]) {
                    numYearsByProb.person2[percentageStr] = calculator.numYearsFromProbability(profiles[1], profiles[1].retirementAge, percentage / 100);
                    numYearsByProb.either[percentageStr] = calculator.numYearsFromProbabilityForEither(percentage / 100, profiles[0], profiles[0].retirementAge, profiles[1], profiles[1].retirementAge);
                    numYearsByProb.both[percentageStr] = calculator.numYearsFromProbabilityForBoth(percentage / 100, profiles[0], profiles[0].retirementAge, profiles[1], profiles[1].retirementAge);
                }
            }
        }

        return numYearsByProb;
    }, [profiles, calculator]);

    const onClickPrint = React.useCallback(event => {
        reportAnalyticsEvent('print_results', {
            numberOfPeople: profiles.length
        });
        event.preventDefault();
        window.print();
    }, []);

    return (
        <div className="results-page">
            <div className="row">
                <div className="col-lg-4 col-print-12">
                    <ResultsPageForm values={values} onUpdateValues={onUpdateValues} />
                </div>
                <div className="col-12 col-print-12 col-lg-8 p-2">
                    <div className="mb-6">
                        <div className="no-print d-lg-none mb-2"><button className="btn btn-outline print-button" onClick={onClickPrint}><img src="/Static/images/icons/print.svg" alt="print icon" aria-hidden="true" /> Print</button></div>
                        <div className="d-flex w-100 align-items-center">
                            <h2 className="flex-grow-1">{headerText}</h2>
                            <div className="no-print d-none d-lg-block"><button className="btn btn-outline" onClick={onClickPrint}><i className="fa fa-print"></i> Print this page</button></div>
                        </div>
                        <div className="mb-4">
                            <div dangerouslySetInnerHTML={{ __html: ResultsPageIntro[values.country][profiles.length.toString()] }}></div>
                        </div>
                        <FAQs country={values.country} numberOfPeople={+values.numberOfPeople} faqs={faqs} title={null} />
                    </div>
                    <ProbLivingToAgeDisplay probSurvivingByAge={probSurvivingByAge} probSurvivingYearsAfterRetirement={probSurvivingYearsAfterRetirement} profiles={profiles} country={values.country} />
                    <PlanningHorizonDisplay numYearsByProbability={numYearsByProbability} profiles={profiles} country={values.country} />
                    <ProbabilityOfLivingASpecifiedNumberOfYearsDisplay probSurvivingYearsAfterRetirement={probSurvivingYearsAfterRetirement} profiles={profiles} country={values.country} />
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: bottomText }}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResultsPage;
