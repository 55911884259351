import React from 'react';
import validatorForFields from '../PeopleEditor/validator';
import PersonColumn from '../PeopleEditor/PersonColumn';
import FormLayout from '../../FormElements/FormLayout';
import SettingsContext from '../../../utils/contexts/SettingsContext';
import { reportAnalyticsEvent } from '../../../utils/ga';

const shownFields = ['healthStatus', 'smoker'];

const PersonLifestylePage = ({ initialValues, onSubmit, personIdx, onGoBack, numberOfPeople }) => {
    const { countries, faqs: { LifestyleFaq }, sectionTitles: { HealthInformation: title } } = React.useContext(SettingsContext);

    React.useEffect(() => {
        reportAnalyticsEvent('view_health_info', {
            personNumber: +personIdx + 1,
            numberOfPeople
        });
    }, []);

    return (
        <FormLayout
            initialValues={initialValues}
            onSubmit={onSubmit}
            validator={validatorForFields(countries, shownFields)}
            title={title}
            personIdx={personIdx}
            faqs={LifestyleFaq}
            onGoBack={onGoBack}
            progress={numberOfPeople == 1 ? 0.66 : (personIdx == 0 ? 0.4 : 0.8)}>
            <PersonColumn idx={personIdx} fieldsToShow={shownFields} />
        </FormLayout>
    )
};

export default PersonLifestylePage;
