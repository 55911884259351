import React from 'react';
import { toGraphData, decimalToPercentage } from './utils';
import Graph from '../Graphs/graph';
import InfoTooltip from './InfoTooltip';
import SettingsContext from '../../utils/contexts/SettingsContext';

const fractionToPercent = (fraction) => fraction === undefined || Number.isNaN(fraction) ? '*' : `${Math.round(fraction * 100)}%`;

const populatedDescription = (text, profiles, probSurvivingYearsAfterRetirement) => {
    let workingCopy = text;

    if (profiles.length == 1) {
        workingCopy = workingCopy.replace(/\{20years_pct\}/g, fractionToPercent(probSurvivingYearsAfterRetirement.person1[20]));
        workingCopy = workingCopy.replace(/\{25years_pct\}/g, fractionToPercent(probSurvivingYearsAfterRetirement.person1[25]));
    } else {
        workingCopy = workingCopy.replace(/\{person1_name\}/g, profiles[0].name);
        workingCopy = workingCopy.replace(/\{person2_name\}/g, profiles[1].name);

        workingCopy = workingCopy.replace(/\{20years_smaller_pct\}/g, fractionToPercent(Math.min(probSurvivingYearsAfterRetirement.person1[20], probSurvivingYearsAfterRetirement.person2[20])));
        workingCopy = workingCopy.replace(/\{20years_larger_pct\}/g, fractionToPercent(Math.max(probSurvivingYearsAfterRetirement.person1[20], probSurvivingYearsAfterRetirement.person2[20])));
        workingCopy = workingCopy.replace(/\{20years_either_pct\}/g, fractionToPercent(probSurvivingYearsAfterRetirement.either[20]));
        workingCopy = workingCopy.replace(/\{20years_both_pct\}/g, fractionToPercent(probSurvivingYearsAfterRetirement.both[20]));
        workingCopy = workingCopy.replace(/\{20years_person1_pct\}/g, fractionToPercent(probSurvivingYearsAfterRetirement.person1[20]));
        workingCopy = workingCopy.replace(/\{20years_person2_pct\}/g, fractionToPercent(probSurvivingYearsAfterRetirement.person2[20]));

        workingCopy = workingCopy.replace(/\{25years_smaller_pct\}/g, fractionToPercent(Math.min(probSurvivingYearsAfterRetirement.person1[25], probSurvivingYearsAfterRetirement.person2[25])));
        workingCopy = workingCopy.replace(/\{25years_larger_pct\}/g, fractionToPercent(Math.max(probSurvivingYearsAfterRetirement.person1[25], probSurvivingYearsAfterRetirement.person2[25])));
        workingCopy = workingCopy.replace(/\{25years_either_pct\}/g, fractionToPercent(probSurvivingYearsAfterRetirement.either[25]));
        workingCopy = workingCopy.replace(/\{25years_both_pct\}/g, fractionToPercent(probSurvivingYearsAfterRetirement.both[25]));
        workingCopy = workingCopy.replace(/\{25years_person1_pct\}/g, fractionToPercent(probSurvivingYearsAfterRetirement.person1[25]));
        workingCopy = workingCopy.replace(/\{25years_person2_pct\}/g, fractionToPercent(probSurvivingYearsAfterRetirement.person2[25]));
    }

    return workingCopy;
}

const ProbabilityOfLivingASpecifiedNumberOfYearsDisplay = ({ probSurvivingYearsAfterRetirement, profiles, country }) => {
    const { resultsPageCopy: { SpecificNumberOfYearsTooltip, SpecificNumberOfYears }, sectionTitles: { ProbabilityOfLivingASpecifiedNumberOfYears: headerText }, template } = React.useContext(SettingsContext);

    const desktopAgeSurvivalGraphData = React.useMemo(() => toGraphData(profiles, 'scatter', probSurvivingYearsAfterRetirement, 'screen', template), [probSurvivingYearsAfterRetirement, profiles]);
    const printAgeSurvivalGraphData = React.useMemo(() => toGraphData(profiles, 'scatter', probSurvivingYearsAfterRetirement, 'print', template), [probSurvivingYearsAfterRetirement, profiles]);

    const probSurvivingNumYearsAfterRetirementTuples = React.useMemo(() => Object.entries(probSurvivingYearsAfterRetirement.person1).sort(([a], [b]) => a - b).map(([numYearsAfterRetirement, person1]) => {
        const tuple = { numYearsAfterRetirement, person1: decimalToPercentage(person1) };

        if (probSurvivingYearsAfterRetirement.person2 && probSurvivingYearsAfterRetirement.person2[numYearsAfterRetirement] !== undefined) {
            tuple.person2 = decimalToPercentage(probSurvivingYearsAfterRetirement.person2[numYearsAfterRetirement]);
            tuple.either = decimalToPercentage(probSurvivingYearsAfterRetirement.either[numYearsAfterRetirement]);
            tuple.both = decimalToPercentage(probSurvivingYearsAfterRetirement.both[numYearsAfterRetirement]);
        }

        return tuple;
    }), [probSurvivingYearsAfterRetirement]);

    return (
        <div className="mb-2">
            <h3>{headerText} <InfoTooltip contents={populatedDescription(SpecificNumberOfYearsTooltip[country][profiles.length.toString()], profiles, probSurvivingYearsAfterRetirement)} id="specific-number-of-years-tooltip" /></h3>
            <div className="print-only">
                <div dangerouslySetInnerHTML={{ __html: SpecificNumberOfYearsTooltip[country][profiles.length.toString()] }}></div>
            </div>
            <div>
                <div dangerouslySetInnerHTML={{ __html: populatedDescription(SpecificNumberOfYears[country][profiles.length.toString()], profiles, probSurvivingYearsAfterRetirement) }}></div>
            </div>
            <div className="no-print">
                <Graph 
                    data={desktopAgeSurvivalGraphData}
                    layout={{
                        xaxis: {
                            type: 'category',
                            tickcolor: 'white',
                            showgrid: false,
                            tickfont: {
                                color: 'white'
                            },
                            title: {
                                text: 'Years From Retirement Age',
                                font: {
                                    color: 'white'
                                }
                            }
                        },
                        yaxis: {
                            range: [0, 1],
                            tickformat: ',.0%',
                            tickcolor: 'white',
                            gridcolor: 'white',
                            tickfont: {
                                color: 'white'
                            },
                            title: {
                                text: 'Probability',
                                font: {
                                    color: 'white'
                                }
                            }
                        },
                        legend: {
                            x: 1,
                            y: 0.5,
                            font: {
                                color: 'white'
                            }
                        },
                        margin: {
                            t: 10
                        },
                        paper_bgcolor: 'transparent',
                        plot_bgcolor: 'transparent'
                    }}
                    id="num-years-display"
                />
            </div>
            <div className="print-only">
                <Graph 
                    data={printAgeSurvivalGraphData}
                    layout={{
                        xaxis: {
                            type: 'category',
                            tickcolor: 'black',
                            showgrid: false,
                            tickfont: {
                                color: 'black'
                            },
                            title: {
                                text: 'Years From Retirement Age',
                                font: {
                                    color: 'black'
                                }
                            }
                        },
                        yaxis: {
                            range: [0, 1],
                            tickformat: ',.0%',
                            tickcolor: 'black',
                            gridcolor: 'black',
                            tickfont: {
                                color: 'black'
                            },
                            title: {
                                text: 'Probability',
                                font: {
                                    color: 'black'
                                }
                            }
                        },
                        legend: {
                            x: 1,
                            y: 0.5,
                            font: {
                                color: 'black'
                            }
                        },
                        margin: {
                            t: 10
                        },
                        paper_bgcolor: 'transparent',
                        plot_bgcolor: 'transparent'
                    }}
                    id="num-years-display-print"
                />
            </div>
            <div className="results-table-wrap no-print-break">
                <table className="table results-table">
                    <thead>
                        <tr>
                            <th>Years</th>
                            <th>{profiles[0].name}</th>
                            {profiles[1] ? (
                                <React.Fragment>
                                    <th>{profiles[1].name}</th>
                                    <th>At least one of {profiles[0].name} and {profiles[1].name}</th>
                                    <th>Both {profiles[0].name} and {profiles[1].name}</th>
                                </React.Fragment>
                            ) : null}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            probSurvivingNumYearsAfterRetirementTuples.map(tuple => (
                                <tr key={tuple.numYearsAfterRetirement}>
                                    <td>{tuple.numYearsAfterRetirement}</td>
                                    <td>{tuple.person1}</td>
                                    {tuple.person2 ? (
                                        <React.Fragment>
                                            <td>{tuple.person2}</td>
                                            <td>{tuple.either}</td>
                                            <td>{tuple.both}</td>
                                        </React.Fragment>
                                    ) : null}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default ProbabilityOfLivingASpecifiedNumberOfYearsDisplay;
