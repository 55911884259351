import { defaultPersonFormValues, fallbackFormValues } from "./constants";
import * as actionTypes from './actionTypes';
import { shouldShowFieldForCountry } from "../../business";

const formValuesReducer = (state = { numberOfPeople: '', country: '', people: [defaultPersonFormValues], countries: {} }, action) => {
    switch (action.type) {
        case actionTypes.SET_COUNTRIES:
            return { ...state, countries: action.payload };
        case actionTypes.SUBMIT_FORM_PAGE:
            const people = action.payload.people;

            // if there are any hidden fields, automatically apply the default value to them
            for (const person of people) {
                for (const fieldKey in person) {
                    if (!shouldShowFieldForCountry(state.countries, person.country, fieldKey) && fallbackFormValues[fieldKey]) {
                        person[fieldKey] = fallbackFormValues[fieldKey];
                    }
                }
            }

            return { ...state, numberOfPeople: action.payload.numberOfPeople, country: action.payload.country, people };
        default:
            return state;
    }
}

export default formValuesReducer;
