import React from 'react';
import SettingsContext from '../../utils/contexts/SettingsContext';
import FAQs from '../FAQs';

const FAQPage = ({ onClose }) => {
    const { faqs: { Overall: overallFaqs } } = React.useContext(SettingsContext);

    const onClickReturn = React.useCallback(event => {
        event.preventDefault();
        onClose();
    }, [onClose]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <FAQs faqs={overallFaqs} />
                    <div className="w-100 text-center">
                        <button className="btn btn-secondary" onClick={onClickReturn}>Return to calculator</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQPage;
