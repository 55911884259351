const initFaq = () => {
    document.querySelectorAll('.js-faq-page .faq').forEach(faq => {
        const btn = faq.querySelector('.btn[data-toggle=collapse]');
        const body = faq.querySelector('.faq__body');

        btn.onclick = event => {
            event.preventDefault();

            if (btn.classList.contains('expanded')) {
                btn.classList.remove('expanded');
            } else {
                btn.classList.add('expanded');
            }
        }
    });
};

export default initFaq;