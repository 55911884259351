import React from 'react';
import clsx from 'clsx';
import SettingsContext from '../../utils/contexts/SettingsContext';

const FAQItem = ({ question, answer, id }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    const onToggle = React.useCallback((currentIsExpanded) => event => {
        event.preventDefault();

        setIsExpanded(!currentIsExpanded);
    }, [setIsExpanded]);

    React.useEffect(() => {
        window.$(`#${id}-answer`).collapse('hide');

        return () => {
            window.$(`#${id}-answer`).collapse('dispose');
        };
    }, [id]);

    return (
        <div className="faq mb-2">
            <div className="faq__header">
                <button className={clsx('btn btn-link btn-block text-left', isExpanded && 'expanded')} type="button" data-toggle="collapse" data-target={`#${id}-answer`} onClick={onToggle(isExpanded)}>
                    <div className="faq__expand-icon">{isExpanded ? <img src="/Static/images/icons/minus.svg" alt="collapse" /> : <img src="/Static/images/icons/plus.svg" alt="expand" />}</div>
                    <div className="faq__question">
                        <div className="faq__question-body">{question}</div>
                    </div>
                </button>
            </div>
            <div className="faq__body collapse" id={`${id}-answer`}>
                <div dangerouslySetInnerHTML={{ __html: answer}}></div>
            </div>
        </div>
    )
}

const FAQs = ({ faqs, title, align = 'center', id = 'faqs', country, numberOfPeople }) => {
    const settingsContext = React.useContext(SettingsContext);

    return (
        <div id={id}>
            {title !== null ? <h2 className={clsx(align === 'center' && 'text-center', align === 'left' && 'text-left', align === 'right' && 'text-right')}>{title || settingsContext.sectionTitles.Faq}</h2> : null}
            {faqs
                .filter(faq => (!faq.Country || faq.Country === country) && (!faq.NumberOfPeople || faq.NumberOfPeople === numberOfPeople))
                .map((faq, idx) => <FAQItem key={idx} question={faq.Question} answer={faq.Answer} id={`faq_${idx}`} />)}
        </div>
    );
};

export default FAQs;
