import React from 'react';
import { Field } from 'formik';

const Dropdown = ({ name, id, options, defaultText }) => {
    return (
        <div className="form-dropdown">
            <Field as="select" className="form-control" name={name} id={id}>
                {
                    defaultText ? <option value="" disabled>{defaultText}</option> : null
                }
                {
                    Object.entries(options).map(([key, text]) => <option key={key} value={key}>{text}</option>)
                }
            </Field>
        </div>
    );
};

export default Dropdown;
