import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from '../store/formValues/selectors';
import { getCurrentPage, getCurrentPersonIndex, getIsShowingFaq } from '../store/progress/selectors';
import * as pages from '../store/progress/pages';
import useAction from '../utils/hooks/useAction';
import { goBack, showFaq, hideFaq, submitPage } from '../store/progress/actions';
import CommonValuesPage from '../components/FormPages/CommonValuesPage';
import { setCountries, submitFormValues } from '../store/formValues/actions';
import PersonDemographicsPage from '../components/FormPages/PersonDemographicsPage';
import ResultsPage from '../components/ResultsPage';
import PersonLifestylePage from '../components/FormPages/PersonLifestylePage';
import SettingsContext from '../utils/contexts/SettingsContext';
import FAQPage from '../components/FAQPage';

const ProbabilityOfSurvivalApp = ({ }) => {
    const { countries } = React.useContext(SettingsContext);

    const formState = useSelector(getFormValues);
    const currentPage = useSelector(getCurrentPage);
    const currentPersonIndex = useSelector(getCurrentPersonIndex);
    const isShowingFaq = useSelector(getIsShowingFaq);

    const onGoBackFromDemographics = useAction(() => goBack(pages.PAGE_PERSON_DEMOGRAPHICS));
    const onGoBackFromLifestyle = useAction(() => goBack(pages.PAGE_PERSON_LIFESTYLE));
    const onSubmitCommonValues = useAction(() => submitPage(pages.PAGE_COMMON_VALUES));
    const onSubmitDemographics = useAction(values => submitPage(pages.PAGE_PERSON_DEMOGRAPHICS, currentPersonIndex, values));
    const onSubmitLifestyle = useAction(values => submitPage(pages.PAGE_PERSON_LIFESTYLE, currentPersonIndex, values));
    const onSubmitFormPage = useAction(submitFormValues);
    const onSetCountries = useAction(setCountries);
    const onShowFaq = useAction(showFaq);
    const onHideFaq = useAction(hideFaq);

    const onSubmitFormPageWithCallback = React.useCallback(progressCallback => values => {
        onSubmitFormPage(values);
        progressCallback(values);  
    }, [onSubmitFormPage]);

    React.useEffect(() => {
        onSetCountries(countries);
    }, [countries, onSetCountries]);

    // make it so that if we click "FAQ" in the navbar (either desktop or mobile) it will go to the FAQ page within the form instead of leaving the page (and thus losing any progress)
    React.useEffect(() => {
        for (const elt of document.getElementsByClassName('js-faqs')) {
            elt.onclick = event => {
                event.preventDefault();

                onShowFaq();
            }
        };

        return () => {
            for (const elt of document.getElementsByClassName('js-faqs')) {
                elt.onclick = undefined;
            };
        };
    }, []);

    let pageBody;
    if (isShowingFaq) {
        pageBody = <FAQPage onClose={onHideFaq} />
    } else {
        switch (currentPage) {
            case pages.PAGE_COMMON_VALUES:
                pageBody = <CommonValuesPage initialValues={formState} onSubmit={onSubmitFormPageWithCallback(onSubmitCommonValues)} />; break;
            case pages.PAGE_PERSON_DEMOGRAPHICS:
                pageBody = <PersonDemographicsPage initialValues={formState} onSubmit={onSubmitFormPageWithCallback(onSubmitDemographics)} onGoBack={onGoBackFromDemographics} personIdx={currentPersonIndex} numberOfPeople={formState.numberOfPeople} />; break;
            case pages.PAGE_PERSON_LIFESTYLE:
                pageBody = <PersonLifestylePage initialValues={formState} onSubmit={onSubmitFormPageWithCallback(onSubmitLifestyle)} onGoBack={onGoBackFromLifestyle} personIdx={currentPersonIndex} numberOfPeople={formState.numberOfPeople} />; break;
            case pages.PAGE_RESULTS:
                pageBody = <ResultsPage values={formState} onUpdateValues={onSubmitFormPage} />; break;
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    {pageBody}
                </div>
            </div>
        </div>
    )
};

const ProbabilityOfSurvivalAppWithSettings = ({ countries, statisticalData, resultsPageCopy, faqs, bottomText, sectionTitles, maxAllowedAge, template, ...props }) => (
    <SettingsContext.Provider value={{ countries, statisticalData, resultsPageCopy, faqs, bottomText, sectionTitles, maxAllowedAge, template }}>
        <ProbabilityOfSurvivalApp {...props} />
    </SettingsContext.Provider>
);

export default ProbabilityOfSurvivalAppWithSettings;
