import React from 'react';
import { useDispatch } from 'react-redux';

const useAction = (action) => {
    const dispatch = useDispatch();

    const callback = React.useCallback(
        (...args) => dispatch(action(...args)),
        [dispatch, action]
    );
    return callback;
};

export default useAction;
