import React from 'react';
import SettingsContext from '../../utils/contexts/SettingsContext';
import FAQs from '../FAQs';
import Graph from '../Graphs/graph';
import { toGraphData } from './utils';

const numberToString = (num, fallback = '*') => num === undefined || Number.isNaN(num) ? fallback : num.toFixed(0);

const populatedDescription = (text, profiles, numYearsByProbability) => {
    const copyForNumber = num => numberToString(num, '*');

    let workingCopy = text;

    if (profiles.length == 1) {
        workingCopy = workingCopy.replace(/\{50pct_years\}/g, copyForNumber(numYearsByProbability.person1['50%']));
        workingCopy = workingCopy.replace(/\{25pct_years\}/g, copyForNumber(numYearsByProbability.person1['25%']));
        workingCopy = workingCopy.replace(/\{10pct_years\}/g, copyForNumber(numYearsByProbability.person1['10%']));
    } else {
        workingCopy = workingCopy.replace(/\{50pct_both_years\}/g, copyForNumber(numYearsByProbability.both['50%']));
        workingCopy = workingCopy.replace(/\{50pct_either_years\}/g, copyForNumber(numYearsByProbability.either['50%']));
        workingCopy = workingCopy.replace(/\{50pct_either_both_diff_years\}/g, copyForNumber(Math.round(numYearsByProbability.either['50%'] - numYearsByProbability.both['50%'])));
        workingCopy = workingCopy.replace(/\{25pct_both_years\}/g, copyForNumber(numYearsByProbability.both['25%']));
        workingCopy = workingCopy.replace(/\{25pct_either_years\}/g, copyForNumber(numYearsByProbability.either['25%']));
        workingCopy = workingCopy.replace(/\{25pct_either_both_diff_years\}/g, copyForNumber(Math.round(numYearsByProbability.either['25%'] - numYearsByProbability.both['25%'])));
        workingCopy = workingCopy.replace(/\{10pct_both_years\}/g, copyForNumber(numYearsByProbability.both['10%']));
        workingCopy = workingCopy.replace(/\{10pct_either_years\}/g, copyForNumber(numYearsByProbability.either['10%']));
        workingCopy = workingCopy.replace(/\{10pct_either_both_diff_years\}/g, copyForNumber(Math.round(numYearsByProbability.either['10%'] - numYearsByProbability.both['10%'])));
    }

    return workingCopy;
}

const PlanningHorizonDisplay = ({ country, profiles, numYearsByProbability }) => {
    const { faqs: { PlanningHorizonFaq }, resultsPageCopy: { PlanningHorizon, PlanningHorizonMissingDataCopy }, sectionTitles: { PlanningHorizon: planningHorizonTitle, PlanningHorizonCertainNumberOfYears: certainNumberOfYearsTitle }, template } = React.useContext(SettingsContext);

    const planningHorizonTableData = React.useMemo(() => Object.entries(numYearsByProbability.person1).sort(([a], [b]) => b - a).filter(([probablity, person1]) => !Number.isNaN(person1)).map(([probability, person1]) => {
        const result = { probability, person1 };

        if (numYearsByProbability.person2) {
            result.person2 = numYearsByProbability.person2[probability];
            result.either = numYearsByProbability.either[probability];
            result.both = numYearsByProbability.both[probability];
        }

        return result;
    }), [numYearsByProbability]);

    const areAnyNumbersMissing = React.useMemo(() => {
        for (const [, series] of Object.entries(numYearsByProbability)) {
            for (const [, val] of Object.entries(series)) {
                if (Number.isNaN(val)) {
                    return true;
                }
            }
        }

        return false;
    }, [numYearsByProbability]);

    const screenPlanningHorizonGraphData = React.useMemo(() => toGraphData(profiles, 'bar', numYearsByProbability, 'screen', template), [profiles, numYearsByProbability]);
    const printPlanningHorizonGraphData = React.useMemo(() => toGraphData(profiles, 'bar', numYearsByProbability, 'print', template), [profiles, numYearsByProbability]);

    return (
        <div className="mb-6">
            <div className="mb-2">
                <h2 className="planning-horizon-heading">{planningHorizonTitle}</h2>
                <div dangerouslySetInnerHTML={{ __html: populatedDescription(PlanningHorizon[country][profiles.length.toString()], profiles, numYearsByProbability) }}></div>
                <div className="no-print mb-4">
                    <FAQs faqs={PlanningHorizonFaq} country={country} numberOfPeople={profiles.length} title="Helpful Hints" id="helpful-hints" align="left" />
                </div>
                <h4>{certainNumberOfYearsTitle}</h4>
                <div className="no-print">
                    <Graph
                        data={screenPlanningHorizonGraphData}
                        layout={{
                            xaxis: {
                                type: 'category',
                                tickcolor: 'white',
                                showgrid: false,
                                tickfont: {
                                    color: 'white'
                                },
                                title: {
                                    text: 'Probability',
                                    font: {
                                        color: 'white'
                                    }
                                }
                            },
                            yaxis: {
                                tickcolor: 'white',
                                gridcolor: 'white',
                                tickfont: {
                                    color: 'white'
                                },
                                title: {
                                    text: 'Years From Retirement Age',
                                    font: {
                                        color: 'white'
                                    }
                                }
                            },
                            legend: {
                                x: 1,
                                y: 0.5,
                                font: {
                                    color: 'white'
                                }
                            },
                            margin: {
                                t: 10
                            },
                            font: {
                                color: 'white',
                                size: 14,
                            },
                            paper_bgcolor: 'transparent',
                            plot_bgcolor: 'transparent'
                        }}
                        id="prob-certain-number-of-years-graph"
                    />
                </div>
                <div className="print-only">
                    <Graph
                        data={printPlanningHorizonGraphData}
                        layout={{
                            xaxis: {
                                type: 'category',
                                tickcolor: 'black',
                                showgrid: false,
                                tickfont: {
                                    color: 'black'
                                },
                                title: {
                                    text: 'Probability',
                                    font: {
                                        color: 'black'
                                    }
                                }
                            },
                            yaxis: {
                                tickcolor: 'black',
                                gridcolor: 'black',
                                tickfont: {
                                    color: 'black'
                                },
                                title: {
                                    text: 'Years From Retirement Age',
                                    font: {
                                        color: 'white'
                                    }
                                }
                            },
                            legend: {
                                x: 1,
                                y: 0.5,
                                font: {
                                    color: 'black'
                                }
                            },
                            margin: {
                                t: 10
                            },
                            font: {
                                color: 'black',
                                size: 14,
                            },
                            paper_bgcolor: 'transparent',
                            plot_bgcolor: 'transparent'
                        }}
                        id="prob-certain-number-of-years-graph-print"
                    />
                </div>
            </div>
            {
                areAnyNumbersMissing ? (
                    <div className="mb-2">
                        <span dangerouslySetInnerHTML={{ __html: PlanningHorizonMissingDataCopy[country][profiles.length.toString()]}} />
                    </div>
                ) : null
            }
            <div className="results-table-wrap no-print-break">
                <table className="table results-table">
                    <thead>
                        <tr>
                            <th rowSpan="2">Probability</th>
                            <th colSpan={profiles[1] ? "4" : "1"}>Years After Retirement</th>
                        </tr>
                        <tr>
                            <th>{profiles[0].name}</th>
                            {profiles[1] ? (
                                <React.Fragment>
                                    <th>{profiles[1].name}</th>
                                    <th>At least one of {profiles[0].name} or {profiles[1].name}</th>
                                    <th>Both {profiles[0].name} and {profiles[1].name}</th>
                                </React.Fragment>
                            ) : null}
                        </tr>
                    </thead>
                    <tbody>
                        {planningHorizonTableData.map(tuple => (
                            <tr key={tuple.probability}>
                                <td>{tuple.probability}</td>
                                <td>{numberToString(tuple.person1)}</td>
                                {
                                    tuple.person2 !== undefined ? (
                                        <React.Fragment>
                                            <td>{numberToString(tuple.person2)}</td>
                                            <td>{numberToString(tuple.either)}</td>
                                            <td>{numberToString(tuple.both)}</td>
                                        </React.Fragment>
                                    ) : null
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PlanningHorizonDisplay;