import React, { useEffect } from 'react';
import mapValues from 'lodash/mapValues';
import SettingsContext from '../../../utils/contexts/SettingsContext';
import RadioButtonSet from '../../FormElements/RadioButtonSet';
import { useFormikContext } from 'formik';

const CommonValuesFragment = ({ fieldsToShow = ['numberOfPeople', 'country'] }) => {
    const { countries, sectionTitles: { WhereDoYouLive: countryQuestionText, HowManyPeople: howManyPeopleQuestionText } } = React.useContext(SettingsContext);

    const { setFieldValue, values: { country } } = useFormikContext();

    useEffect(() => {
        // if there is only country, auto-select it
        if (!country && countries && Object.keys(countries).length === 1) {
            setFieldValue('country', Object.keys(countries)[0]);
        }
    }, [setFieldValue, countries, country])

    return (
        <React.Fragment>
            {
                fieldsToShow.includes('numberOfPeople') ? (
                    <div className="form-group">
                        <label>{howManyPeopleQuestionText}</label>
                        <RadioButtonSet name="numberOfPeople" options={{
                            '1': 'Myself',
                            '2': 'Myself and someone else'
                        }} />
                    </div>
                ) : null
            }
            {
                (fieldsToShow.includes('country') && Object.keys(countries).length > 1) ? (
                    <div className="form-group">
                        <label>{countryQuestionText}</label>
                        <RadioButtonSet name="country" options={mapValues(countries, 'DisplayName')} />
                    </div>
                ) : null
            }
        </React.Fragment>
    )
};

export default CommonValuesFragment;