import get from 'lodash/get';

const maxKey = (obj, ...toExclude) => {
    let maxSoFar = undefined;
    for (const key in obj) {
        if (!toExclude.includes(key) && (maxSoFar === undefined || key > maxSoFar)) {
            maxSoFar = key;
        }
    }

    return maxSoFar;
}

export class ProbabilityOfSurvivalCalculator {
    constructor(statisticalData) {
        this.statisticalData = statisticalData;
    }

    /**
     * The base probability of survival based only on age and sex, ignoring all other load adjustments and mortality improvements
     * @param {any} profile
     * @param {any} age
     */
    baseProbabilityOfSurvival(profile, age) {
        const result = +get(this.statisticalData.BaseMortality, `[${profile.country}][${profile.sex}][${age}]`, NaN);

        if (result > 0.99999) // if the mortality rate is exactly 1 (use >0.99999 because of round-of error) then this can be interpreted as not having any data present
            return NaN;
        
        return result;
    };

    /**
     * The smoking adjustment
     * @param {any} profile
     * @param {any} age
     */
    smokingAdjustment(profile, age) {
        if (!this.statisticalData.SmokingFactor[profile.country]) {
            return 1;
        }

        const smokingStatus = profile.smoker === 'true' || profile.smoker === true;

        return +get(this.statisticalData.SmokingFactor, `[${profile.country}][${smokingStatus ? 'TRUE' : 'FALSE'}][${age}]`, NaN);
    };

    /**
     * The health status load adjustment
     * @param {any} profile
     * @param {any} age
     */
    healthStatusLoadAdjustment(profile, age) {
        return +get(this.statisticalData.HealthStatusLoad, `[${profile.country}][${profile.healthStatus}][${age}]`, NaN);
    };

    /**
     * The mortality improvement factor
     * @param {any} profile
     * @param {any} age
     */
    mortalityImprovement(profile, age) {
        const year = profile.birthYear + age;

        const baseMortalityImprovement = get(this.statisticalData.MortalityImprovement, `[${profile.country}][${profile.sex}][${year}]`, NaN);
        if (baseMortalityImprovement) {
            return +this.statisticalData.MortalityImprovement[profile.country][profile.sex][year][age];
        } else {
            const mortalityDataByYear = get(this.statisticalData.MortalityImprovement, `[${profile.country}][${profile.sex}]`, NaN);
            if (!mortalityDataByYear) {
                return NaN;
            }

            const ultimateMortalityFactor = get(this.statisticalData.MortalityImprovement, `[${profile.country}][${profile.sex}][ultimate][${age}]`, NaN);
            if (!ultimateMortalityFactor) {
                return NaN;
            }

            const maxYear = maxKey(mortalityDataByYear, 'ultimate');
            const lastFullYearMortalityImprovement = get(mortalityDataByYear, `[${maxYear}][${age}]`);
            if (!lastFullYearMortalityImprovement) {
                return NaN;
            }
            
            return +lastFullYearMortalityImprovement * Math.pow(+ultimateMortalityFactor, year - (+maxYear));
        }
    };

    /**
     * The probability (ignoring mortality improvement) that someone dies at a given age
     * @param {any} profile
     * @param {any} age
     */
    qx(profile, age) {
        return this.baseProbabilityOfSurvival(profile, age) * this.smokingAdjustment(profile, age) * this.healthStatusLoadAdjustment(profile, age);
    } 

    /**
     * The probability (including mortality improvement) that someone dies at a given age
     * @param {any} profile
     * @param {any} age
     */
    loadedQx(profile, age) {
        return this.qx(profile, age) * this.mortalityImprovement(profile, age);
    }

    /**
     * Return the probability someone has of reaching a given age
     * @param {any} profile
     * @param {any} endAge
     */
    totalProbabilityOfSurvival(profile, startAge, endAge) {
        let age = startAge;
        let tpx = 1;

        while (age < endAge) {
            tpx *= (1 - this.loadedQx(profile, age));

            age++;
        }

        return tpx;
    };

    /**
     * Return the age someone has a certain probability of reaching
     * @param {object} profile
     * @param {number} probability
     */
    numYearsFromProbability(profile, startAge, probability) {
        let delta = 0;

        while (true) {
            const probSurvival = this.totalProbabilityOfSurvival(profile, startAge, startAge + delta);
            if (!probSurvival) {
                return NaN;
            }

            if (probSurvival < probability) {
                return delta - 1;
            }

            delta++;
        }
    };

    numYearsFromProbabilityForEither(probability, profile1, startAge1, profile2, startAge2) {
        let delta = 0;

        while (true) {
            const probSurvival = this.probabilityOfOneSurvivingToAge(profile1, startAge1, startAge1 + delta, profile2, startAge2, startAge2 + delta);
            if (!probSurvival) {
                return NaN;
            }
            
            if (probSurvival < probability) {
                return delta - 1;
            }

            delta++;
        }
    }

    numYearsFromProbabilityForBoth(probability, profile1, startAge1, profile2, startAge2) {
        let delta = 0;

        while (true) {
            const probSurvival = this.probabilityOfBothSurvivingToAge(profile1, startAge1, startAge1 + delta, profile2, startAge2, startAge2 + delta);
            if (!probSurvival) {
                return NaN;
            }

            if (probSurvival < probability) {
                return delta - 1;
            }

            delta++;
        }
    }

    probabilityOfOneSurvivingToAge(profile1, startAge1, endAge1, profile2, startAge2, endAge2) {
        const prob1 = this.totalProbabilityOfSurvival(profile1, startAge1, endAge1);
        const prob2 = this.totalProbabilityOfSurvival(profile2, startAge2, endAge2);

        return prob1 + prob2 - prob1 * prob2;
    };

    probabilityOfBothSurvivingToAge(profile1, startAge1, endAge1, profile2, startAge2, endAge2, age2) {
        return this.totalProbabilityOfSurvival(profile1, startAge1, endAge1) * this.totalProbabilityOfSurvival(profile2, startAge2, endAge2);
    }
};

// mapping from field name -> country field (from epi) for whether or not to show it
const countryFieldsToHide = {
    smoker: 'ShowSmoking'
};

export const shouldShowFieldForCountry = (countries, country, field) => !countries[country] || !countryFieldsToHide[field] || countries[country][countryFieldsToHide[field]];

/*
Example:
ageFromProbability({
    smoker: false,
    age: 65,
    birthYear: 1957, // round this to match the notion of rounded age
    sex: 'male',
    healthStatus: 'average'
}, .95) => 69 (i.e. this person has a 95% chance of surviving to age 69)

totalProbabilityOfSurvival({
    smoker: false,
    age: 65,
    birthYear: 1957, // round this to match the notion of rounded age
    sex: 'male',
    healthStatus: 'average'
}, .95) => 0.9394 (i.e. this person has about a 94% chance of surviving to age 70)
*/