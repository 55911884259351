import React from 'react';
import { Formik, useFormikContext } from 'formik';
import PersonBadge from '../FormPages/PeopleEditor/PersonBadge';
import PersonColumn from '../FormPages/PeopleEditor/PersonColumn';
import SettingsContext from '../../utils/contexts/SettingsContext';
import validatorForFields from '../FormPages/PeopleEditor/validator';

const shownFields = ['name', 'country', 'age', 'sex', 'retirementAge', 'smoker', 'healthStatus'];

const MobileEditFooterComponent = ({ personIdx, onClick }) => {
    return (
        <div className="results-page-footer__person-column">
            <PersonBadge personIdx={personIdx} />
            <button className="btn btn-primary" onClick={onClick}>Edit</button>
        </div>
    );
}

const MobileResultsPageForm = ({ onSubmit, initialValues }) => {
    const { countries } = React.useContext(SettingsContext);
    const [mobilePersonIdx, setMobilePersonIdx] = React.useState(null);
    const scrollPos = React.useRef(null);
    const scrollAreaRef = React.useRef();

    const onClickSubmit = React.useCallback(formValues => {
        setMobilePersonIdx(null);
        onSubmit(formValues)
    }, [onSubmit]);

    // when we open the form editing drawer on mobile, scroll to the top so the header is visible, but save the current position and go back to it when we close the editor
    React.useEffect(() => {
        if (mobilePersonIdx === null && scrollPos.current) {
            document.body.style.overflow = 'auto';
            window.scrollTo(scrollPos.current[0], scrollPos.current[1]);
        } else if (mobilePersonIdx !== null) {
            document.body.style.overflow = 'hidden';
            scrollPos.current = [window.scrollX, window.scrollY];
            window.scrollTo(0, 0);
        }
    }, [mobilePersonIdx, scrollPos]);

    const setMobilePersonIdxEventHandler = React.useCallback(idx => event => {
        event.preventDefault();
        setMobilePersonIdx(idx);
    }, [setMobilePersonIdx]);

    const onClickRemove = React.useCallback((setFieldValue, submitForm, people, numberOfPeople, idx) => event => {
        event.preventDefault();

        setFieldValue('people', [...people.slice(0, idx), ...people.slice(idx + 1)]);
        setFieldValue('numberOfPeople', +numberOfPeople - 1);
        setMobilePersonIdx(null);
        submitForm();
    }, [setMobilePersonIdx]);

    const onClickAdd = React.useCallback((setFieldValue, numberOfPeople) => event => {
        event.preventDefault();

        setFieldValue('numberOfPeople', +numberOfPeople + 1);
        setMobilePersonIdx(+numberOfPeople); // since this is a zero-based index, we need to set to the number of people minus one
        
        // need to scroll the mobile scroll area back to the top since it's a new person
        if (scrollAreaRef.current) {
            scrollAreaRef.current.scroll(0, 0);
        }
    }, [setMobilePersonIdx, scrollAreaRef]);

    return (
        <Formik initialValues={initialValues} onSubmit={onClickSubmit} validate={validatorForFields(countries, shownFields)} validateOnBlur={false} validateOnChange={false}>
            {
                ({ handleSubmit, values, setFieldValue, submitForm }) => (
                    mobilePersonIdx == null ? (
                        <div className="results-page-footer no-print">
                            <MobileEditFooterComponent personIdx={0} onClick={setMobilePersonIdxEventHandler(0)} />
                            {
                                +values.numberOfPeople > 1 ?
                                    <MobileEditFooterComponent personIdx={1} onClick={setMobilePersonIdxEventHandler(1)} /> :
                                    null
                            }
                        </div>
                    ) : (
                        <div className="results-page-form-mobile" ref={scrollAreaRef}>
                            <form onSubmit={handleSubmit} className="results-page-form">
                                <div className="d-flex results-page-form__header align-items-center">
                                    <PersonBadge personIdx={mobilePersonIdx} />
                                    <div className="flex-spacer"></div>
                                    {
                                        +values.numberOfPeople > 1 ? (
                                            <button type="button" className="remove-person-button" onClick={onClickRemove(setFieldValue, submitForm, values.people, values.numberOfPeople, mobilePersonIdx)}>
                                                Remove
                                            </button>
                                        ) : null
                                    }
                                    <button type="button" className="btn-close" onClick={setMobilePersonIdxEventHandler(null)}><img src="/Static/images/icons/x.svg" alt="close" /></button>
                                </div>
                                <PersonColumn idx={mobilePersonIdx} fieldsToShow={shownFields} resultsPage={true} />
                                {
                                    +values.numberOfPeople === 1 ? (
                                        <div>
                                            <button type="button" className="add-person-button" onClick={onClickAdd(setFieldValue, values.numberOfPeople)}>
                                                <i className="fa fa-plus"></i> Add second person
                                            </button>
                                        </div>
                                    ) : null
                                }
                                <div className="text-center results-page-form-mobile__footer">
                                    <button type="submit" className="btn btn-primary btn__update-results">Update Results</button>
                                </div>
                            </form>
                        </div>
                    )
                )
            }
        </Formik>
    )
};

export default MobileResultsPageForm;
