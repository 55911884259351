import React from 'react';
import clsx from 'clsx';

const InfoTooltip = ({ id, contents, className = '' }) => {
    const itemRef = React.useRef();

    React.useEffect(() => {
        if (contents && itemRef.current) {
            window.$(itemRef.current).tooltip('enable');

            return () => {
                window.$(itemRef.current).tooltip('dispose');
            }
        }
    }, [id, contents, itemRef]);

    if (!contents) {
        return null;
    }

    return (
        <span id={id} ref={itemRef} className={clsx('info-tooltip no-print', className)} data-toggle="tooltip" title={contents} data-html="true" data-placement="bottom">
            <img src="/Static/images/icons/info.svg" alt="info tooltip" />
        </span>
    );
};

export default InfoTooltip;