import { useFormikContext } from 'formik';
import React from 'react';
import get from 'lodash/get';

const idFromNameAndValue = (key, value) => {
    return `${key.replace(/[\[\]\.]/g, '_')}_${value}`
}

const RadioButtonSet = ({ name, options }) => {
    const { values, handleChange, errors, setFieldValue } = useFormikContext();
    const value = get(values, name);
    const error = get(errors, name);

    const onClickLabel = React.useCallback((event) => {
        setFieldValue(name, event.target.control.value);
    }, []);

    return (
        <div>
            {Object.entries(options).map(([key, text], idx) => (
                <React.Fragment key={key}>
                    <input type="radio" className="btn-check" name={name} id={idFromNameAndValue(name, idx)} value={key} checked={value == key} onChange={handleChange} />
                    <label className="btn" htmlFor={idFromNameAndValue(name, idx)} onClick={onClickLabel}>{text}</label>
                </React.Fragment>
            ))}
            {error ? (
                <div className="tooltip tooltip-error bs-tooltip-bottom show" role="tooltip">
                    <div className="arrow"></div>
                    <div className="tooltip-inner">
                        {error}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default RadioButtonSet;