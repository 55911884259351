import React from 'react';
import PropTypes from 'prop-types';
import mapValues from 'lodash/mapValues';
import { useFormikContext, Field } from 'formik';
import { defaultPersonFormValues } from '../../../store/formValues/constants';
import { shouldShowFieldForCountry } from '../../../business';
import RadioButtonSet from '../../FormElements/RadioButtonSet';
import SettingsContext from '../../../utils/contexts/SettingsContext';
import InfoTooltip from '../../ResultsPage/InfoTooltip';
import Dropdown from '../../FormElements/Dropdown';

const FormContext = React.createContext();

const PersonFormField = ({ label, propName, id, children, hideError }) => {
    const {
        errors: overallFormErrors,
        values: overallFormValues
    } = useFormikContext();

    const { countries } = React.useContext(SettingsContext);
    const { idx, fieldsToShow } = React.useContext(FormContext);

    const peopleErrors = (overallFormErrors.people || []);
    const errors = peopleErrors[idx] || {};

    const shouldShowField = React.useCallback(field => fieldsToShow.includes(field) && shouldShowFieldForCountry(countries, overallFormValues.country, field), [countries, fieldsToShow, overallFormValues.country]);

    return shouldShowField(propName) ? (
        <div className="form-group">
            <label htmlFor={id}>{label}</label>
            {children}
            {!hideError && errors[propName] ? (
                <div className="tooltip tooltip-error bs-tooltip-bottom show" role="tooltip">
                    <div className="arrow"></div>
                    <div className="tooltip-inner">
                        {errors[propName]}
                    </div>
                </div>
            ) : null}
        </div>
    ) : null;
}

const RetirementAgeField = ({ idx, resultsPage = false }) => {
    const { values } = useFormikContext();

    const {
        resultsPageCopy: { RetirementAgeTooltip },
        sectionTitles: {
            RetirementAge: retirementAgeTitle
        },
        maxAllowedAge,
    } = React.useContext(SettingsContext);

    if (idx === 0) { // show the retirement age field for the first person
        return (
            <PersonFormField label={<React.Fragment>
                {retirementAgeTitle} <InfoTooltip contents={RetirementAgeTooltip[values.country]} id="retirement-age-tooltip" className="info-tooltip-gray" />
            </React.Fragment>} propName="retirementAge" id={`retirementAge_${idx}`}>
                <Field type="number" name={`people[${idx}].retirementAge`} id={`retirementAge_${idx}`} min={Math.max(18, +values.people[0].age)} max={maxAllowedAge} className="form-control form-control__narrow" />
            </PersonFormField>
        );
    } else if (resultsPage) {
        const retirementAge = (values.people[0] && values.people[1] && values.people[1].age) ? +values.people[1].age + (+values.people[0].retirementAge - +values.people[0].age) : undefined;

        return (
            <div className="form-group">
                <label htmlFor={`retirementAge_${idx}`}>{retirementAgeTitle}</label>
                <input type="text" id={`retirementAge_${idx}`} disabled className="form-control form-control__narrow" value={retirementAge} />
            </div>
        )
    } else {
        return null;
    }
};

const PersonColumn = ({ idx, fieldsToShow, className, resultsPage = false }) => {
    const {
        values: overallFormValues,
        errors,
        setFieldValue,
    } = useFormikContext();

    React.useEffect(() => {
        if (!overallFormValues.people[idx]) {
            setFieldValue(`people.${idx}`, defaultPersonFormValues);
        }
    }, [overallFormValues]);

    const {
        countries,
        sectionTitles: {
            Name: nameTitle,
            WhereDoYouLive: whereDoYouLiveTitle,
            Age: ageTitle,
            Sex: sexTitle,
            DoYouSmoke: doYouSmokeTitle,
            OverallHealthStatus: overallHealthStatusTitle
        },
        maxAllowedAge,
    } = React.useContext(SettingsContext);

    return (
        <div className={className}>
            <FormContext.Provider value={{ idx, fieldsToShow }}>
                <PersonFormField label={nameTitle} propName="name" id={`name_${idx}`}>
                    <Field type="text" name={`people[${idx}].name`} id={`name_${idx}`} className="form-control" />
                </PersonFormField>
                {
                    fieldsToShow.includes('country') ? (
                        <div className="form-group">
                            <label htmlFor={`country_${idx}`}>{whereDoYouLiveTitle}</label>
                            <Dropdown options={mapValues(countries, 'DisplayName')} name="country" id={`country_${idx}`} defaultText="Select a country..." />
                            {
                                errors.country ? (
                                    <div className="tooltip tooltip-error bs-tooltip-bottom show" role="tooltip">
                                        <div className="arrow"></div>
                                        <div className="tooltip-inner">
                                            {errors.country}
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : null
                }
                <PersonFormField label={ageTitle} propName="age" id={`age_${idx}`}>
                    <Field type="number" name={`people[${idx}].age`} id={`age_${idx}`} max={maxAllowedAge} className="form-control form-control__narrow" min="18" />
                </PersonFormField>
                <PersonFormField label={sexTitle} propName="sex" id={`sex_${idx}`} hideError>
                    <RadioButtonSet name={`people[${idx}].sex`} options={{
                        male: 'Male',
                        female: 'Female'
                    }} />
                </PersonFormField>

                <RetirementAgeField idx={idx} resultsPage={resultsPage} />
                
                <PersonFormField label={doYouSmokeTitle} propName="smoker" id={`smoker_${idx}`} hideError>
                    <RadioButtonSet name={`people[${idx}].smoker`} options={{
                        'true': 'Yes',
                        'false': 'No'
                    }} />
                </PersonFormField>
                <PersonFormField label={overallHealthStatusTitle} propName="healthStatus" id={`healthStatus_${idx}`}>
                    <Dropdown options={{
                        poor: 'Poor',
                        average: 'Average',
                        excellent: 'Excellent'
                    }} id={`healthStatus_${idx}`} name={`people[${idx}].healthStatus`} defaultText="Select an option..." />
                </PersonFormField>
            </FormContext.Provider>
        </div>
    )
};

PersonColumn.propTypes = {
    idx: PropTypes.number.isRequired,
    fieldsToShow: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PersonColumn;
