import * as pages from './pages';
import * as actionTypes from './actionTypes';

const progressReducer = (state = { showingFaq: false, page: pages.FIRST_PAGE, person: 0 }, action) => {
    switch (action.type) {
        case actionTypes.SUBMIT_PAGE:
            switch (action.payload.page) {
                case pages.PAGE_COMMON_VALUES:
                    return { ...state, page: pages.PAGE_PERSON_DEMOGRAPHICS };
                case pages.PAGE_PERSON_DEMOGRAPHICS:
                    return { ...state, page: pages.PAGE_PERSON_LIFESTYLE };
                case pages.PAGE_PERSON_LIFESTYLE:
                    if (state.person + 1 == action.payload.formValues.numberOfPeople) {
                        return { ...state, page: pages.PAGE_RESULTS }
                    } else {
                        return { ...state, page: pages.PAGE_PERSON_DEMOGRAPHICS, person: state.person + 1 }
                    }
                default:
                    return state;
            }
        case actionTypes.GO_BACK:
            switch (action.payload.page) {
                case pages.PAGE_PERSON_DEMOGRAPHICS:
                    if (state.person >= 1) {
                        return { ...state, page: pages.PAGE_PERSON_DEMOGRAPHICS, person: state.person - 1 };
                    } else {
                        return { ...state, page: pages.PAGE_COMMON_VALUES };
                    }
                case pages.PAGE_PERSON_LIFESTYLE:
                    return { ...state, page: pages.PAGE_PERSON_DEMOGRAPHICS };
            }
        case actionTypes.SHOW_FAQ:
            return { ...state, showingFaq: true };
        case actionTypes.HIDE_FAQ:
            return { ...state, showingFaq: false };
        default:
            return state;
    }
};

export default progressReducer;